import React, { useState } from 'react';
import { Link } from "gatsby";
import { DropdownMenuTrigger, DropdownMenuItem, DropdownMenuContent, DropdownMenu } from "@/components/ui/dropdown-menu";
import { ChevronDownIcon } from "../icons";
import NavItem from "./NavItem";
import { menuItems } from "./menuItems";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Menu } from "lucide-react";
import LangSelector from "../translation/LangSelector";

export default function NavBar() {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isSheetOpen, setIsSheetOpen] = useState(false);

  const handleToggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const handleCloseDropdown = () => {
    setOpenDropdown(null);
  };

  const toggleSheet = () => {
    setIsSheetOpen(!isSheetOpen);
  };

  return (
    <section className="relative z-50">
      <div className="relative container mx-auto flex items-center justify-between py-4 px-6">
        <Link className="flex items-center gap-2" to="/">
            <img
              src="/WCWS25-Logo.svg"
              alt="5WCWS Logo"
              width={100}
              className="mx-auto"
            />
            <p className="font-bold mb-0">
              <span className="text-dteal">
                5th World Conference of Women's Shelters<br/>
                15-18 Sept 2025
              </span><br/>
              <span className="text-dbrown">Sydney, Australia</span>
            </p>
        </Link>

        <nav className="hidden xl:flex space-x-4 relative text-black">
          {menuItems.header.map((item, index) => (
            item.dropdown ? (
              <DropdownMenu key={index}>
                <DropdownMenuTrigger
                  className="flex items-center hover:text-dteal cursor-pointer"
                  onClick={() => handleToggleDropdown(index)}
                >
                  {item.label}
                  <ChevronDownIcon className="h-4 w-4 ml-1" />
                </DropdownMenuTrigger>
                {openDropdown === index && (
                  <DropdownMenuContent className="absolute mt-2 w-56 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none lg:right-[-50px]">
                    {item.items.map((subItem, subIndex) => (
                      <DropdownMenuItem key={subIndex} onClick={handleCloseDropdown}>
                        <Link to={subItem.to} className="w-full">{subItem.label}</Link>
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                )}
              </DropdownMenu>
            ) : (
              <NavItem key={index} to={item.to}>{item.label}</NavItem>
            )
          ))}
          <LangSelector />
        </nav>

        <nav className="xl:hidden flex items-center bg-dbrown p-3 fixed top-5 right-5">
          <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
            <SheetTrigger asChild>
              <button onClick={toggleSheet} aria-label="Open Menu">
                <Menu className="h-6 w-6" />
              </button>
            </SheetTrigger>
            <SheetContent side="right" className="w-10/12">
              <SheetHeader className="flex flex-row items-center gap-5" id="mobile-nav-header">
                <SheetTitle className="text-dbrown font-black text-2xl mb-0">Menu</SheetTitle>
                <LangSelector />
              </SheetHeader>
              <div className="mt-4">
                {menuItems.header.map((item, index) => (
                  <React.Fragment key={index}>
                    <div className="my-2">
                      {item.dropdown ? (
                        <>
                          <button
                            className="w-full py-2 text-left flex items-center justify-between font-bold text-gray-600"
                            onClick={() => handleToggleDropdown(index)}
                          >
                            {item.label}
                            <ChevronDownIcon className="h-4 w-4" />
                          </button>
                          {openDropdown === index && (
                            <div className="mt-2 pl-4">
                              {item.items.map((subItem, subIndex) => (
                                <Link
                                  key={subIndex}
                                  to={subItem.to}
                                  className="flex py-1 text-gray-600"
                                  onClick={() => setIsSheetOpen(false)}
                                >
                                  {subItem.label}
                                </Link>
                              ))}
                            </div>
                          )}
                        </>
                      ) : (
                        <Link
                          to={item.to}
                          className="block py-2 text-gray-600 font-bold"
                          onClick={() => setIsSheetOpen(false)}
                        >
                          {item.label}
                        </Link>
                      )}
                    </div>
                    <hr/>
                  </React.Fragment>
                ))}
              </div>
            </SheetContent>
          </Sheet>
        </nav>
      </div>
    </section>
  );
}
