export const menuItems = {

    // header menu items
    header: [
      {
        label: "Home",
        to: "/",
        dropdown: false,
      },
      {
        label: "About",
        to: "",
        dropdown: true,
        items: [
          { label: "About the hosts", to: "/about-the-hosts" },
          { label: "Invitation", to: "/invitation" },
          { label: "Conference committees", to: "/conference-committee" },
          { label: "Code of conduct", to: "/code-of-conduct" },
          { label: "Networking", to: "/networking" },
          { label: "FAQs", to: "/faq" },
          { label: "About our logo", to: "/about-our-logo" },
          { label: "Sustainability", to: "/sustainability" },
          { label: "Past conferences", to: "/past-conferences" },
          { label: "Wellness", to: "/wellness" },
          { label: "Contact us", to: "/contact-us" },
        ],
      },
      {
        label: "Registration",
        to: "",
        dropdown: true,
        items: [
          { label: "Registration", to: "/registration" },
          { label: "Accommodation", to: "/accommodation" },
          { label: "Visa requirements", to: "/visa-requirements" },
        ],
      },
      {
        label: "Program",
        to: "",
        dropdown: true,
        items: [
          { label: "Program overview", to: "/program-overview" },
          { label: "Call for abstracts", to: "/call-for-abstracts" },
          { label: "Speakers", to: "/speakers" },
          { label: "Scholarships", to: "/scholarships" },
          { label: "Study tours", to: "/study-tours" },
          { label: "Side events", to: "/side-events" },
        ],
      },
      {
        label: "Support us",
        to: "",
        dropdown: true,
        items: [
          { label: "Sponsor or Exhibitor", to: "/sponsor" },
          { label: "Spread the word", to: "/spread-the-word" },
          { label: "Volunteer", to: "/volunteer" },
          { label: "Donate", to: "/donate" },
          { label: "Fundraising resources", to: "/fundraising-resources" },
        ],
      },
      {
        label: "Plan your visit",
        to: "",
        dropdown: true,
        items: [
          { label: "Our venue", to: "/our-venue" },
          { label: "Explore Sydney & Australia", to: "/explore-sydney" },
          { label: "Transport", to: "/transport" },
        ],
      },
      {
        label: "News",
        to: "",
        dropdown: true,
        items: [
          { label: "Latest news", to: "/latest-news" },
        ],
      },
    ],

    // footer menu items
    footer: [
      {
        label: "About",
        links: [
          { label: "About the hosts", to: "/about-the-hosts" },
          { label: "Invitation", to: "/invitation" },
          { label: "Conference committees", to: "/conference-committee" },
          { label: "Code of conduct", to: "/code-of-conduct" },
          { label: "Networking", to: "/networking" },
          { label: "FAQs", to: "/faq" },
          { label: "About our logo", to: "/about-our-logo" },
          { label: "Sustainability", to: "/sustainability" },
          { label: "Past conferences", to: "/past-conferences" },
          { label: "Wellness", to: "/wellness" },
          { label: "Contact us", to: "/contact-us" },
        ],
      },
      {
        label: "Registration",
        links: [
          { label: "Registration", to: "/registration" },
          { label: "Accommodation", to: "/accommodation" },
          { label: "Visa requirements", to: "/visa-requirements" },
        ],
      },
      {
        label: "Program",
        links: [
          { label: "Program overview", to: "/program-overview" },
          { label: "Call for abstracts", to: "/call-for-abstracts" },
          { label: "Speakers", to: "/speakers" },
          { label: "Scholarships", to: "/scholarships" },
          { label: "Study tours", to: "/study-tours" },
          { label: "Side events", to: "/side-events" },
        ],
      },
      {
        label: "Support us",
        links: [
          { label: "Sponsor or Exhibitor", to: "/sponsor" },
          { label: "Spread the word", to: "/spread-the-word" },
          { label: "Volunteer", to: "/volunteer" },
          { label: "Donate", to: "/donate" },
          { label: "Fundraising resources", to: "/fundraising-resources" },
        ],
      },
      {
        label: "Plan your visit",
        links: [
          { label: "Our venue", to: "/our-venue" },
          { label: "Explore Sydney & Australia", to: "/explore-sydney" },
          { label: "Transport", to: "/transport" },
        ],
      },
      {
        label: "News",
        links: [
          { label: "Latest news", to: "/latest-news" },
        ],
      },
    ],

    // social menu items
  social: [
    {
      name: "facebook",
      url: "https://www.facebook.com/5WCWS",
      screenReader: "Follow us on Facebook",
    },
    {
      name: "linkedin",
      url: "https://www.linkedin.com/company/wesnet_au",
      screenReader: "Follow us on LinkedIn",
    },
    {
      name: "instagram",
      url: "https://www.instagram.com/wesnetaustralia/",
      screenReader: "Follow us on Instagram",
    },
    {
      name: "twitter",
      url: "https://twitter.com/wesnetaustralia",
      screenReader: "Follow us on Twitter",
    },
  ]
};

