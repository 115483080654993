const pageData = {
    "/": {
      backgroundImage: "/images/5wcws-home-hero.webp",
      heading: "Welcome",
      description: "Welcome to the 5th World Conference of Women's Shelters 2025",
    },
    "/about-our-logo/": {
      // backgroundImage: "/images/color-book.webp",
      heading: "About our logo",
      description: `Our locally designed 5WCWS logo has been inspired by the traditional art of First Nations Australians. It was designed by Saretta Fielding from <a href="https://www.saretta.com.au/" target="_blank" rel="noopener noreferrer" class="text-dteal">Saretta Art & Design</a>, an Aboriginal Woman of Wonnarua Nation.`,
    },
    "/accommodation/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "Accommodation",
      description: "Find the best places to stay during your event.",
    },
    "/conference-committee/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "Conference committees",
      description: "Thank you to all our volunteers from around the globe.",
    },
    "/program-overview/": {
      // backgroundImage: "/images/library.webp",
      heading: "Program overview",
      description: "Get a program overview of the 5th World Conference of Women's Shelters.",
    },
    "/explore-sydney/": {
      backgroundImage: "/images/explore-sydney.webp",
      heading: "Explore Sydney & Australia",
      description: "Make the most of your trip to 5WCWS!",
    },
    "/visa-requirements/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "Visa requirements",
      description: "Ensure you organise your visa as early as you can.",
    },
    "/latest-news/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "Latest news",
      description: "Stay updated with the latest news and updates.",
    },
    "/contact-us/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "Contact us",
      description: `No matter what your enquiry, we are here to help you.`,
    },
    "/code-of-conduct/": {
      backgroundImage: "/images/chinatown.webp",
      heading: "Code of conduct",
      description: "Read our Code of conduct to ensure a safe and respectful event.",
    },
    "/about-the-hosts/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "About the hosts",
      description: "Meet the people who are hosting the event.",
    },
    "/sponsor/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "Sponsor or Exhibitor",
      description: "Learn about our amazing opportunities and who are current sponsors, exhibitors and supporters.",
    },
    "/our-venue/": {
      backgroundImage: "/images/venue.webp",
      heading: "Our venue",
      description: "Explore the venue where our event will take place.",
    },
    "/past-conferences/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "Past conferences",
      description: "Take a look at the highlights from the past World Shelter conferences.",
    },
    "/registration/": {
      backgroundImage: "/images/botanical.webp",
      heading: "Registration",
      description: "Register for the event quickly and easily with our streamlined process. We can’t wait for you to join us in Sydney.",
    },
    "/call-for-abstracts/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "Call for abstracts",
      description: "Submit your abstracts to share your work, research and insights at 5WCWS.",
    },
    "/speakers/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "Speakers",
      description: "Discover our lineup of distinguished speakers.",
    },
    "/spread-the-word/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "Spread the word",
      description: "Please use this promotion pack to spread the word about 5WCWS to your peers and networks.",
    },
    "/side-events/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "Side events",
      description: "Join the exciting side events to enhance your Conference experience.",
    },
    "/study-tours/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "Study tours",
      description: "Join a study tour to gain in-depth knowledge and insights.",
    },
    "/sustainability/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "Sustainability",
      description: "Sustainability is a top priority for 5WCWS.",
    },
    "/transport/": {
      backgroundImage: "/images/tram.webp",
      heading: "Transport",
      description: "Use these tips to easily get around Sydney, one of the most accessible cities in the world.",
    },
    "/scholarships/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "Scholarships",
      description: "We have scholarships on offer for those who need financial assistance to attend.",
    },
    "/networking/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "Networking",
      description: "Connect with others from around the world while enjoying the tastes of Australia.",
    },
    "/wellness/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "Wellness",
      description: "We look forward to providing space and opportunity for all delegates to access self-care and a way to connect to others in care.",
    },
    "/donate/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "Donate",
      description: "Your generosity is very welcome.",
    },
    "/fundraising-resources/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "Fundraising resources",
      description: "Here are some helpful hints to help you raise funds to attend 5WCWS.",
    },
    "/faq/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "FAQs",
      description: "We hope you find our FAQs helpful.",
    },
    "/invitation/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "Invitation",
      description: "We invite you to 5WCWS in Sydney, Australia.",
    },
    "/volunteer/": {
      // backgroundImage: "/images/wcws-crowd.webp",
      heading: "Volunteer",
      description: "We need your help!",
    }
  };

  export const getPageData = (path) => {
    return pageData[path] || {};
  };
