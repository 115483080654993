import React from 'react'
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
  } from "@/components/ui/sheet"
  import { CalendarRange } from "lucide-react";
  import useGoogleSheetData from "@/components/useGoogleSheet";

  export function KeyDates() {

    const { data: data, loading, error } = useGoogleSheetData(
        "1i2Bw2EVK0viMttXY89PPdqjuzzazlKux8qx4aXwJvYI",
        "Key Dates"
      );

    return (
        <>
            <Sheet>
                <SheetTrigger className="fixed bottom-5 left-5 rounded-lg p-3 text-xl font-bold drop-shadow-lg hover:drop-shadow-xl flex items-center gap-2 uppercase bg-dbrown text-white z-50"><CalendarRange /><span className="hidden lg:block">Key Dates</span></SheetTrigger>
                <SheetContent side={"left"} className="overflow-y-auto h-screen">
                    <SheetHeader>
                        <SheetTitle className="mb-0 pb-0 text-dbrown text-2xl">Key Dates</SheetTitle>
                        <SheetDescription>
                        {error && <div>Error: {error.message}</div>}
                            {!loading && !error && data[0].data.map((item, index) => (
                            <div key={index} className="border-b py-2">
                                <h3 className="text-lg pt-1 mb-0">{item.title}</h3>
                                <p className="text-base font-semibold text-black mb-0">{item.date}</p>
                            </div>
                        ))}
                        </SheetDescription>
                    </SheetHeader>
                </SheetContent>
            </Sheet>
        </>
    )
}


export function KeyDatesBar() {

    const { data: data, loading, error } = useGoogleSheetData(
        "1i2Bw2EVK0viMttXY89PPdqjuzzazlKux8qx4aXwJvYI",
        "Key Dates"
      );

      return (
        <>
            {error && <div>Error: {error.message}</div>}
                {!loading && !error && data[0].data.map((item, index) => (
                <div key={index} className="border-b border-lorange py-6 px-5">
                    <h3 className="text-lg pt-1 mb-0 text-ivory">{item.title}</h3>
                    <p className="text-base font-semibold text-white/80 mb-0">{item.date}</p>
                </div>
            ))}
        </>
      )

}