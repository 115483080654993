import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import InLineNavBar from "./navbar/InLineNavBar";
import UserForm from '@/components/form/EOI-Form';
import { ConfettiBtn } from "./ConfettiBtn";
import { getPageData } from '../data/pageData';
import { menuItems } from "./navbar/menuItems";
import { Facebook, Linkedin, Instagram, Twitter } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"

const Header = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { backgroundImage, heading, description } = getPageData(location.pathname);
  const [backgroundSize, setBackgroundSize] = useState('1300px');

  const eventName = '5WCWS 2025';
  const eventDate = '2024-07-03';
  const isHomePage = location.pathname === "/";

  const iconMap = {
    facebook: <Facebook strokeWidth={2} size={30} color="white"/>,
    linkedin: <Linkedin strokeWidth={2} size={30} color="white" />,
    instagram: <Instagram strokeWidth={2} size={30} color="white" />,
    twitter: <Twitter strokeWidth={2} size={30} color="white" />,
  };

  useEffect(() => {
    const dialogDismissed = document.cookie.split('; ').find(row => row.startsWith('dialogDismissed='));

    if (!dialogDismissed) {
      const timer = setTimeout(() => {
        setOpen(true);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    const updateBackgroundSize = () => {
      if (window.innerWidth <= 1024) {
        setBackgroundSize('contain');
      } else {
        setBackgroundSize('1300px');
      }
    };

    updateBackgroundSize();

    window.addEventListener('resize', updateBackgroundSize);

    return () => {
      window.removeEventListener('resize', updateBackgroundSize);
    };
  }, []);

  const handleDialogClose = () => {
    setOpen(false);
    // Set cookie to expire in 1 day
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 1);
    document.cookie = `dialogDismissed=true; expires=${expiryDate.toUTCString()}; path=/`;
  };

  return (
    <>
      <header className="relative text-white overflow-x-clip">
        <section className="bg-white">
          <InLineNavBar />
        </section>
        {isHomePage ? (
        <section
          className="relative inset-0"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
          }}
        >
          <div className="container mx-auto px-6 py-20 relative z-10">
            <div className="grid grid-cols-1 lg:grid-cols-2 items-center min-h-[250px] lg:min-h-[300px] xl:min-h-[500px]">
              <div />
              <div className="text-end">
                <div className="bg-black/40 backdrop-blur-sm inline-block p-10 rounded-xl">
                  <h1 className="accent-font text-6xl">Connect.<br/>Challenge.<br/>Change.</h1>
                  <h2 className="text-lteal">15 - 18 September 2025</h2>
                  <div className="flex flex-col gap-3">
                    <div>
                      <ConfettiBtn btnText="Super early bird closes 17 December" link="/registration" />
                    </div>
                    {/* <div>
                      <ConfettiBtn btnText="Call for abstracts close 31 Oct" link="/call-for-abstracts" />
                    </div> */}
                  </div>
                  <div className="flex flex-row gap-3 order-1 lg:order-2 justify-end mt-5 items-end">
                    <span className="font-bold">#5WCWS</span>
                    {menuItems.social.map((social, index) => (
                      <a
                        href={social.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={index}
                        className="text-slate-900 hover:text-dteal flex items-center"
                        aria-label={social.screenReader}
                      >
                        {iconMap[social.name.toLowerCase()]}
                      </a>
                    ))}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      ) : ( null )}

        <section
          className={`relative z-10 min-h-[250px] md:min-h-[300px] lg:min-h-[500px] bg-ivory ${!isHomePage ? 'flex flex-col justify-end h-full' : 'hidden'}`}
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
          }}
        >
          {backgroundImage ? (
            <div className="pg-overlay absolute w-full h-52 z-10" />
          ) : (
            <>
              <div
                className="absolute w-full h-full z-20"
                style={{
                  backgroundImage: 'url(/images/WCWS2025-webpage-header.webp)',
                  backgroundColor: 'bg-ivory',
                  backgroundSize: backgroundSize,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center top',
                }}
              />
              <div className="bg-gradient-to-r from-ivory/0 via-ivory to-ivory/0 absolute container mx-auto w-full h-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10" />
              <div
                className="absolute w-full h-full opacity-20"
                style={{
                  backgroundImage: 'url(/images/watermark-side-graphics.webp)',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'repeat-x',
                  backgroundPosition: 'center center',
                }}
              />
            </>
          )}
          <div className={`container mx-auto h-full px-6 py-12 ${isHomePage ? 'grid grid-cols-1 gap-12 md:grid-cols-2 items-center' : ''} `}>
            <div className="relative overflow-hidden z-20">
              {isHomePage ? (
                <>
                  <Dialog open={open} onOpenChange={handleDialogClose}>
                    <DialogContent>
                      <DialogHeader>
                        <DialogTitle className="text-2xl font-bold">Express your interest</DialogTitle>
                        <UserForm eventName={eventName} eventDate={eventDate} isInHeader={true} />
                      </DialogHeader>
                    </DialogContent>
                  </Dialog>
                </>
              ) : (
                <div className="flex lg:flex-row justify-between items-end gap-5">
                  <div>
                    <h1 className="text-3xl mb-5 text-white sm:text-4xl md:text-5xl drop-shadow-md">
                      {heading}
                    </h1>
                    <p className="text-white page-desc mb-0 drop-shadow-md" dangerouslySetInnerHTML={{ __html: description }} />
                  </div>
                  <div className="hidden lg:flex flex-row gap-3 order-1 lg:order-2 justify-center items-end">
                    <span className="font-bold">#5WCWS</span>
                    {menuItems.social.map((social, index) => (
                      <a
                        href={social.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={index}
                        className="text-slate-900 hover:text-dteal flex items-center"
                        aria-label={social.screenReader}
                      >
                        {iconMap[social.name.toLowerCase()]}
                        <span className="sr-only">{social.screenReader}</span>
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div />
          </div>
        </section>
      </header>
    </>
  );
}

export default Header;
